<template>

  <v-container>

    <!-- Section imgHeader -->
    <section id="imgHeader">
      <v-layout column wrap class="my-5" align-center>
        <v-card class="mx-auto" width="90%">
          <v-card-text
            class="body-1"
          >
            <v-img
                :src="require('../assets/img/affiches_nantes.png')"
            ></v-img>
            <p>Affiches réalisées pour la campagne #UNunie contre le harcèlement à retrouver sur le site de l'Université de Nantes</p>
          </v-card-text>
        </v-card>
      </v-layout>
    </section>

    <!-- Section paragraphe1 -->
    <section id="paragraphe1">
      <v-layout column wrap class="my-5" align-center>
        <v-card class="mx-auto" width="90%">
          <v-card-title class="section-title">
            <h1 class="display-2 font-weight-bold mb-3">
              "C'était mieux avant !"
            </h1>
          </v-card-title>
          <v-card-text
            class="body-1"
          >
            <p class="text--primary">
                C’était mieux avant ! Avant, personne ne s’indignait quand
                on traitait les arbitres d’en***és. Avant, les nénettes
                mettaient des talons et c’était sympa à regarder. Avant, on
                n’avait pas l’impression de ne plus être en France quand on
                sortait dans la rue... (un peu d’humour voyons !)
                C’est par ces tristes lignes, que nous espérons aussi
                choquantes pour vous que pour nous, que nous
                souhaitons vous présenter un nouveau groupe de
                travail : le Comité Inclusivité de l’AISC. Comme son nom
                l’indique, il s’agit d’un comité qui souhaite prévenir et
                limiter les comportements discriminatoires
                auxquels peuvent être confronté·e·s les élèves et
                ingénieur·e·s de SIGMA Clermont. Nous avons
                globalement deux objectifs. Le premier est de
                sensibiliser et d’informer les élèves et les alumnis sur les
                discriminations et les violences dans le milieu
                professionnel. De nombreuses informations seront aussi
                directement applicables à la sphère personnelle. Le
                second est de soutenir les institutions en place à Sigma
                Clermont (BDE, scolarité, etc) dans leur démarche de
                lutte contre les discriminations.
            </p>
          </v-card-text>
        </v-card>
      </v-layout>
    </section>

    <!-- Section paragraphe2 -->
    <section id="paragraphe2">
      <v-layout column wrap class="my-5" align-center>
        <v-card class="mx-auto" width="90%">
          <v-card-title class="section-title">
            <h1 class="display-2 font-weight-bold mb-3">
              Concrètement pour vous ?
            </h1>
          </v-card-title>
          <v-card-text
            class="body-1"
          >
            <p class="text--primary">
                Concrètement pour vous ? Nous souhaitons apporter des
                réponses aux questions que vous pouvez vous poser, et
                même à celles auxquelles vous n’avez jamais pensé. Par
                exemple, savez-vous vraiment ce qu’est le harcèlement
                sexuel ? Et en tant que témoin, connaissez-vous vos
                responsabilités ? Êtes- vous vraiment certain·e·s de ne
                jamais avoir été en position de harceleur·euse? Les
                termes homophobie, sexisme et racisme vous parlent.
                Qu’en est-il du validisme, de la psychophobie ou encore
                de l’outrage sexiste ? Toutes ces questions et bien
                d'autres nécessitent des réponses et sont essentielles à
                traiter pour instaurer des climats inclusifs et
                bienveillants.
                Nous souhaitons réellement que ce comité soit un moyen
                d’échange et de collaboration avec vous. C’est pourquoi
                nous allons créer et vous transmettre du contenu de
                différentes sortes (articles, sondages, affiches, etc.) et
                espérons vous rencontrer durant des conférences
                privées (Masterclass) organisées à l’école. Vous pouvez
                dors et déjà trouver des ressources intéressantes en
                vous promenant sur le site de l’AISC.
                Vous pouvez nous contacter par mail si vous souhaitez
                intégrer cette belle équipe et participer à ce projet avec
                le temps et l’énergie que vous avez à donner !
            </p>
          </v-card-text>
        </v-card>
      </v-layout>
    </section>

  </v-container>
</template>

<script>
  export default {
    name: 'NousConnaitre',

    data: () => ({
      
    }),
  }
</script>
